@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html,
body,
#root,
.app {
    width: 100%;
    font-family: "Inter", sans-serif;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
  .nav-list::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .nav-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 

  @media (max-width: 768px) {
    .vendor-signup-container {
      width: 80% !important;
    }
    .availability-item {
      flex-direction: column !important;
    }
    .availability-day-toggle {
      text-align: center !important;
    }
    .availability-item > div {
      width: 100% !important;
    }
  }

  @media (min-width : 768px ) and (max-width : 1024px) {
    .vendor-signup-container {
      width: 70% !important;
    }
  }

  @media (min-width : 1024px ) and (max-width : 1440px) {
    .vendor-signup-container {
      width: 50% !important;
    }
  }

  @media (min-width : 1440px ) {
    .vendor-signup-container {
      width: 40% !important;
    }
  }
  